.app-container {
  max-width: 500px;
}

.link-word {
  font-weight: 700;
  text-transform: uppercase;
}

.remove-decoration {
  text-decoration: none;
  color: unset;
}
